// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-hafa-samband-js": () => import("./../../../src/pages/hafa-samband.js" /* webpackChunkName: "component---src-pages-hafa-samband-js" */),
  "component---src-pages-heilsumarkthjalfun-js": () => import("./../../../src/pages/heilsumarkthjalfun.js" /* webpackChunkName: "component---src-pages-heilsumarkthjalfun-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-radgjof-js": () => import("./../../../src/pages/radgjof.js" /* webpackChunkName: "component---src-pages-radgjof-js" */),
  "component---src-pages-um-erlu-js": () => import("./../../../src/pages/um-erlu.js" /* webpackChunkName: "component---src-pages-um-erlu-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

