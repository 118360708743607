module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HG249N2SN4"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MX6PSJF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HeilsuErla, heilsumarkþjálfun og ráðgjöf með Erlu","short_name":"HeilsuErla","description":"Erla Guðmundsdóttir, heilsumarkþjálfi og ráðgjafi, býður upp á heildræna og einstaklingsmiðaða ráðgjöf og þjálfun fyrir einstaklinga og hópa sem vilja taka skref í átt að bættri heilsu og öðlast heilbrigðan lífsstíl til frambúðar.","start_url":"/","background_color":"#f2f5f6","theme_color":"#00788c","display":"standalone","icon":"src/assets/Logo_HE.png","lang":"is","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a34e4e1132b400a67c546810d146f712"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
